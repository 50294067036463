<template>
  <div class="wrapper">

    <Menu></Menu>

    <Slider></Slider>

<!--    <Boosted></Boosted>-->

    <div class="body mt-1">

      <Highlights></Highlights>

      <div class="row flex-row flex-nowrap hide-scrollbar" style="height: 50px;overflow-y: scroll;scrollbar-width: none;-ms-overflow-style: none;padding-left: 15px;background-color: #d4d4d43d;padding-right: 15px;">

        <div class="col-auto countries-row" v-for="(d,index) in countries" v-bind:key="getKey(index)" v-bind:class="getSelectedClass(d)" @click="setCategory(d)">
          <span v-text="d.country"></span> | <span class="countries-count" v-text="d.count"></span>
        </div>

        <!--

        <div class="btn-group">

          <span
              v-bind:class="getMarketClass('sport')"
              class="dropdown-toggle"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              v-text="sport_name">
          </span>

          <div class="dropdown-menu">
            <span
                v-for="s in sports"
                v-bind:key="getKey(s.sport_id)"
                class="dropdown-item sport_dropdown_item black-txt"
                @click="setSport(s)"
                v-text="s.sport_name">
            </span>
          </div>

        </div>

        <div class="btn-group">
          <span
              v-bind:class="getMarketClass('all')"
              @click="setUpcomingAll">
            All
          </span>
        </div>
        -->

        <!-- Top Leagues dropdown -->
        <!--
        <div class="btn-group" v-for="(d,index) in dates" v-bind:key="index">

          <span
              v-bind:class="getMarketClass(d.date)"
              @click="setUpcomingDate(d.date,index,d.long)">
            {{ getDayName(index, d.label) }}
          </span>

        </div>
        -->

      </div>

      <div class="sport_dropdowns row no-margin" style="padding-left: 20px;">

        <div class="col-12 text-left highlight-title-text">
          <span v-text="selectedOption"></span>
        </div>

      </div>

      <div class="countries">

        <div v-for="(c,index) in tournaments" v-bind:key="getCountryKey(c,index)" class="countries-item">

          <div @click="loadData(index)" class="trend-item" v-bind:class="selectedClass(index)">

            <div class="trend-text2">
              {{ c.category_name }} / {{ c.tournament }}
            </div>

            <div class="trend-text2">
              {{ c.count }}
              <span style="position: relative;top: 6px;"><arrow-right-icon style="height: 1em;"></arrow-right-icon></span>
            </div>

          </div>

          <fixture
              v-show="isVisisble(index)"
              v-for="(l,index1) in c.data"
              v-bind:key="getLeagueKey(l,index1)"
              v-bind:fixture="l"></fixture>

          <div class="timeline-wrapper" v-show="amIBusy(index)">

            <div class="timeline-item">
              <div class="animated-background facebook">

                <!-- lines -->

                <div class="background-masker" style="top: 0px; left: 0px; width: 60%; height: 7px; "></div>

                <div class="background-masker" style="top: 17px; left: 0px; width: 60%; height: 7px; "></div>

                <div class="background-masker" style="top: 34px; left: 0px; width: 60%; height: 7px; "></div>

                <div class="background-masker" style="top: 51px; left: 0px; width: 60%; height: 7px; "></div>

                <div class="background-masker" style="bottom: 0px; left: 0px; width: 60%; height: 5px; "></div>

                <!-- odds separator -->
                <div class="background-masker" style="top: 0; bottom: 0; left: 59%; width: 1%; "></div>

                <div class="background-masker" style="top: 0px; right: 0px; width: 40%; height: 7px; "></div>
                <div class="background-masker" style="bottom: 0px; right: 0px; width: 40%; height: 7px; "></div>

                <!-- odds separator -->
                <div class="background-masker" style="top: 0; bottom: 0; left: 79%; width: 1%; "></div>

              </div>
            </div>

          </div>

          <!--          <CountryGames v-show="isVisisble(index)" v-bind:leagues="c.data" v-bind:tournament_id="c.tournament_id" v-bind:load="isLoading(index)"/>-->

        </div>

      </div>

      <div class="timeline-wrapper" v-show="busy">

        <div class="timeline-item">

          <div class="animated-background facebook">

            <!-- lines -->

            <div class="background-masker" style="top: 0px; left: 0px; width: 60%; height: 7px; "></div>

            <div class="background-masker" style="top: 17px; left: 0px; width: 60%; height: 7px; "></div>

            <div class="background-masker" style="top: 34px; left: 0px; width: 60%; height: 7px; "></div>

            <div class="background-masker" style="top: 51px; left: 0px; width: 60%; height: 7px; "></div>

            <div class="background-masker" style="bottom: 0px; left: 0px; width: 60%; height: 5px; "></div>

            <!-- odds separator -->
            <div class="background-masker" style="top: 0; bottom: 0; left: 59%; width: 1%; "></div>

            <div class="background-masker" style="top: 0px; right: 0px; width: 40%; height: 7px; "></div>
            <div class="background-masker" style="bottom: 0px; right: 0px; width: 40%; height: 7px; "></div>

            <!-- odds separator -->
            <div class="background-masker" style="top: 0; bottom: 0; left: 79%; width: 1%; "></div>


          </div>

        </div>

      </div>
      <div class="timeline-wrapper" v-show="busy">

        <div class="timeline-item">

          <div class="animated-background facebook">

            <!-- lines -->

            <div class="background-masker" style="top: 0px; left: 0px; width: 60%; height: 7px; "></div>

            <div class="background-masker" style="top: 17px; left: 0px; width: 60%; height: 7px; "></div>

            <div class="background-masker" style="top: 34px; left: 0px; width: 60%; height: 7px; "></div>

            <div class="background-masker" style="top: 51px; left: 0px; width: 60%; height: 7px; "></div>

            <div class="background-masker" style="bottom: 0px; left: 0px; width: 60%; height: 5px; "></div>

            <!-- odds separator -->
            <div class="background-masker" style="top: 0; bottom: 0; left: 59%; width: 1%; "></div>

            <div class="background-masker" style="top: 0px; right: 0px; width: 40%; height: 7px; "></div>
            <div class="background-masker" style="bottom: 0px; right: 0px; width: 40%; height: 7px; "></div>

            <!-- odds separator -->
            <div class="background-masker" style="top: 0; bottom: 0; left: 79%; width: 1%; "></div>


            <!--        <div class="background-masker header-top" style="background-color: red"></div>-->
            <!--        <div class="background-masker header-left"></div>-->
            <!--        <div class="background-masker header-right">g</div>-->
            <!--        <div class="background-masker header-bottom">h</div>-->

            <!--        <div class="background-masker subheader-left">i</div>-->
            <!--        <div class="background-masker subheader-right">j</div>-->
            <!--        <div class="background-masker subheader-bottom">k</div>-->

            <!--        <div class="background-masker content-top">l</div>-->

          </div>

        </div>

      </div>

      <BottomFooter></BottomFooter>
      <bottom-navigation active-bets="0" page="countries"></bottom-navigation>
    </div>

  </div>
</template>

<script>
const Highlights = () => import('./Highlights.vue')
const Slider = () => import('./Slider.vue')
const BottomFooter = () => import('./BottomFooter.vue')
// const Boosted = () => import('./Boosted.vue')
const Menu = () => import('./Menu.vue')
const BottomNavigation = () => import('./BottomNavigation.vue')
//const CountryGames = () => import('./CountryGames.vue')
const Fixture = () => import('./Fixture.vue')

import fixtures from "../services/fixtures";
import mqtt from "mqtt";

export default {
  name: 'Countries',
  components: {
    Menu,
    Slider,
    Highlights,
    BottomFooter,
    BottomNavigation,
    //CountryGames,
    Fixture,
    // Boosted,
  },
  data: function () {
    return {
      sub_page: '',
      loading: [],
      visible: [],
      is_busy: false,
      busy: false,
      amBusy: [],
      countries: [],
      mqttClient: false,
      dates: [],
      sport_name: 'Sports',
      selected_hour: 0,
      hours: [-1, 1, 3, 6, 12, 24, 48],
      menu: 'all',
      selectedOption: 'A-Z Countries',
      category_id: 0,
      tournaments: [],
    }
  },
  mounted() {

    this.$store.dispatch("setCurrentPage", "home");
    this.$store.dispatch("setSportID", "1");
    this.resetFilters();

    this.reloadProfile();
    this.getCountries();
    this.initMqtt();

  },
  computed: {
    sports: function () {
      return this.$store.state.sports
    },
    betslip_count: function () {

      return this.$store.state.betslip.total

    },
    current_sub_page: function () {

      return this.$store.state.current_sub_page;
    },
    sport_id: function () {
      return this.$store.state.sport_id;
    },
    market_id: function () {
      return this.$store.state.market_id;
    },
    hour: function () {

      return -1;
      // this.$store.state.hour;
    },
    date: function () {

      return this.$store.state.upcoming_date

    }
  },
  methods: {

    reset: function(){

      this.tournaments = []
      this.loading = []
      this.visible = []
      this.amBusy = []

    },
    getLeagueKey: function(league,index){

      var prefix = league === undefined || league.competition_id == undefined ? index : league.competition_id;
      return Math.random().toString(10).replace('0.', 'competition-id-'+prefix + '-');

    },

    getSelectedClass: function (d){

      return parseInt(d.category_id) === parseInt(this.category_id) ? 'selected' : ''

    },

    setCategory: function (d) {

      this.category_id = d.category_id

      if(parseInt(d.category_id) === 0 ) {

        this.selectedOption = 'A-Z Countries'

      } else {

        this.selectedOption = d.country

      }

      this.reset();

      this.getTournaments()

    },

    getMarketClass: function (menu) {

      return menu === this.menu ? 'market-list-active' : 'market-list';

    },

    getKey: function (index) {

      return Math.random().toString(20).replace('0.', 'random-id-' + index + '-');

    },

    setHour: function (h) {

      this.selected_hour = h;
      this.$store.dispatch("setHour", h);

    },
    humanHourName: function (h) {

      if (h === -1) {

        return 'All Time'
      }

      return h === '' || h === "" || h === 0 ? 'Time' : h + ' Hours'

    },

    initMqtt: function () {

      var vm = this;

      if (this.mqttClient !== false) {

        this.mqttClient.end();

      }

      var market_id = 1;
      var sport_id = this.sport_id === 0 ? 1 : this.sport_id;

      // subcribe to specific sportID (this.sport_id) and all matches but only specific match this.market_id odds changes
      var topic = 'topic/producer-3/sport-' + sport_id + '/+/market-' + market_id;

      var client = mqtt.connect(process.env.VUE_APP_URL_MQTT_HOST);

      client.on('connect', function () {
        client.subscribe(topic, function (err) {
          if (!err) {

            console.log('subscribed to topic ' + topic);

          }
        })
      })

      client.on('message', function (topic, msg) {
        // message is Buffer
        var payload = JSON.parse(msg.toString());
        vm.oddsUpdate(payload);
      })

      this.mqttClient = client;

    },

    getName: function (d) {

      return d.country + ' ('+d.count+')';

    },

    getCountries: function () {

      var vm = this;
      var sport_id = this.sport_id;
      var date = this.date;

      var path = "/country/"+sport_id+"/summary";

      vm.busy = true;
      fixtures.get(path, {params: {
        hours: vm.hour,
        date: date,
      }})
          .then(res => {

            vm.busy = false;

            let count = 0;
            let countries = []

            countries.push({
              "count": count,
              "country": "All",
              "country_code": "all",
              "category_id": 0,
              "category_name": "All"
            })

            vm.jQuery.each(res.data, function (k,v){

              count = count + parseInt(v.count)
              countries.push(v)

            })

            countries[0].count = count

            vm.countries = countries;

            vm.setCategory(countries[0])

          })
          .catch(error => {

            console.log(error)

          })
    },

    getTournaments: function () {

      var vm = this;
      var path = "/sport/"+this.sport_id+"/tournament/"+this.category_id+"/summary"
      vm.busy = true;

      fixtures.get(path, {params: {
          highlight_market_id: 0,
          date: vm.date,
        }})
          .then(res => {

            vm.busy = false;
            vm.tournaments = res.data

            vm.jQuery.each(vm.tournaments, function(k,v){

              if(v.data !== null && v.data.length > 0  ) {

                vm.loadData(k)

              } else {

                v.data = []

              }

              vm.tournaments[k] = v

            })

          })
          .catch(error => {

            console.log(error)

          })
    },

    getHighlight: function (index, tournament) {

      var vm = this;

      var path = "/highlights/{sport_id}"
      var sport_id = this.$store.state.sport_id;
      var date = this.$store.state.date;
      var market_id = this.$store.state.market_id;

      path = path.replace("{sport_id}", sport_id);

      let payload = {
        page: 1,//vm.page,
        per_page: 20,//vm.per_page,
        highlight_market_id: market_id,
        tournament_id: tournament.tournament_id,
        category_id: 0,
        date: date,
        upcoming: 0,
        boosted: 0,
        today: 0,
      }

      vm.setAmBusy(index)

      fixtures.get(path, {params: payload})
          .then(res => {

            vm.setAmNotBusy(index)

            var highlightsRawData = res.data.data;

            vm.jQuery.each(highlightsRawData, function (k, v) {

              v.competition_id = v.tournament_id;
              highlightsRawData[k] = v;

            });

            vm.tournaments[index].data = highlightsRawData;

          })

          .catch(err => {

            vm.setAmNotBusy(index)

            if (err.response) {

              console.error(JSON.stringify(err.response.data.message));

            } else if (err.request) {

              console.error(JSON.stringify(err.request));

            } else {

              console.error(JSON.stringify(err));

            }

          })
    },

    isLoading: function (index) {


      const i = this.visible.indexOf(index);

      return i <= -1;


    },

    selectedClass: function (index) {

      const i = this.visible.indexOf(index);
      if (i > -1) {

        return 'selected';

      }

      return 'selected';
    },
    isVisisble: function (index) {

      const i = this.visible.indexOf(index);

      return i > -1;

    },
    loadData: function (index) {

      const i = this.loading.indexOf(index);

      console.log('loadData '+index+' is at index '+i)

      if (i > -1) {  // index already exisits in loading array

        // toggle visibility
        const i = this.visible.indexOf(index);

        // check if its visible
        if (i > -1) {

          this.visible.splice(i, 1); // hide visibility

        } else {

          this.visible.push(index); // else make visible

        }

      } else {

        this.loading.push(index);
        // make visible
        this.visible.push(index);

      }

      let tournament = this.tournaments[index]

      if(tournament.data.length === 0 ) {

        this.getHighlight(index, tournament)

      }

    },

    setAmBusy: function (index) {

      const i = this.amBusy.indexOf(index);

      if (i < 0 ) {

        this.amBusy.push(index);

      }

    },
    setAmNotBusy: function (index) {

      const i = this.amBusy.indexOf(index);

      if (i > -1 ) {

        this.amBusy.splice(i, 1); // hide visibility

      }

    },
    amIBusy: function (index) {

      const i = this.amBusy.indexOf(index);

      return i > -1

    },

    getCountryKey: function (league, index) {

      var prefix = league === undefined || league.tournament_id == undefined ? index : league.tournament_id;
      return Math.random().toString(10).replace('0.', 'tournament_id-' + prefix + '-');

    },
    setSport: function (sport) {

      this.menu = 'sport';
      this.sport = sport;
      this.sport_name = sport.sport_name;
      this.selectedOption = this.sport_name;
      this.$store.dispatch("setSportID", sport.sport_id);
      this.$store.dispatch("setSportName", sport.sport_name);
    },
    geChildPageActiveClass: function (childpage) {

      return childpage === this.$store.state.upcoming_date ? 'selected-date' : "";

    },
    setUpcomingDate: function (date,index,label) {

      this.menu = date;
      this.$store.dispatch("setUpcomingDate", date)
      this.selectedOption = this.getDayName(index, label)+'s Matches';

    },
    setUpcomingAll: function () {

      this.menu = 'all';
      this.$store.dispatch("setUpcomingDate", '');
      this.selectedOption = 'Upcoming Matches';
    },
    getDayName: function (i, label) {

      console.log('index ==> ' + i + ' label ==> ' + label);

      if (i === 0) {

        return 'Today';
      }

      if (i === 1) {

        return 'Tomorrow';

      }

      return label;

    },
  },
  watch: {
    sport_id: function (newValue, oldValue) {

      if (parseInt(oldValue) > 0) {

        this.getCountries();
        this.initMqtt();

      }

    },

    hour: function (newValue, oldValue) {

      if (parseInt(newValue) !== parseInt(oldValue)) {

        return;

      }

      console.log('Hour =>newValue ==> ' + newValue + ' oldValue ==> ' + oldValue);
      this.is_busy = true;
      this.getCountries();
    },

    date: function (newValue, oldValue) {

      console.log('date newValue ==> ' + newValue + ' oldValue ==> ' + oldValue);
      this.getCountries();

    },

    competition_id: function (newValue, oldValue) {

      if (parseInt(newValue) !== parseInt(oldValue)) {

        return;

      }

      this.competitions = [];

      if (this.highlights) {

        this.per_page = 20;
        this.page = 0;
        this.last_page = 0;
        this.remaining_records = 0;
        this.getHighlight();

      } else {

        if (this.visible_leagues.length > 0) {

          this.getFixture(this.visible_leagues.join(','));

        }

      }

    },

  },
  beforeDestroy: function () {
    console.log("beforeDestroy")
    if (this.mqttClient !== false) {

      this.mqttClient.end();
      this.mqttClient = false;

    }

  },
  destroyed: function () {

    console.log("destroyed")
    if (this.mqttClient !== false) {

      this.mqttClient.end();
      this.mqttClient = false;

    }
  }
}
</script>

<style scoped>

.countries-row{
  padding: 15px 10px 0px 10px;
  margin: 2px;
}

.countries-row.selected {

  border-bottom-color: var(--primary);
  border-bottom-width: 4px;
  border-bottom-style: solid;
  color: var(--primary);
}

.countries-count{

  padding: 0px;
  margin: 1px;
  font-weight: 700;
}

</style>